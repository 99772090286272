import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        list: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('units', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        show: (cntx, { id }) => {
            axios.get(`units/${id}`)
                .then(response => resolve(response))
                .catch(error => reject(error))
        },
        create: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.post('units', params)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        update: (cntx, params) => {
            console.log(params)
            return new Promise((resolve, reject) => {
                axios.put(`units/${params.id}`, params.data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        remove: (cntx, id) => {
            return new Promise((resolve, reject) => {
                axios.delete(`units/${id}`)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        // seasons: (cntx, params) => {
        //     return new Promise((resolve, reject) => {
        //         axios.get('seasons', { params: params })
        //             .then(response => resolve(response))
        //             .catch(error => reject(error))
        //     })
        // },
        // currencies: (cntx, params) => {
        //     return new Promise((resolve, reject) => {
        //         axios.get('currencies', { params: params })
        //             .then(response => resolve(response))
        //             .catch(error => reject(error))
        //     })
        // },
    }
}
